<template>
  <!-- Data table for contct list: start -->

  <v-row class="py-0">
    <v-col cols="12">
      <v-overlay :value="loading" z-index="1000">
        <v-snackbar v-model="loading" timeout="-1" centered>
          <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
          <span class="py-2 px-2">Processing...</span>
        </v-snackbar>
      </v-overlay>
      <!-- Snackbar for small info alerts -->
      <v-snackbar
        :value="showSnackbar"
        timeout="-1"
        :vertical="true"
        app
        bottom
        right
        style="opacity: 0.9; z-index: 2005"
        content-class="main-snackbar-content d-flex flex-column align-start justify-start"
      >
        <div class="py-0" style="font-size: 15px">
          <b>{{ snackbarText }}</b>
        </div>
        <div class="py-1 text-capitalize" style="font-size: 13px">
          <template v-if="typeof snackbarText2 == 'string'">
            <span>{{ snackbarText2 }}</span>
          </template>
          <template v-else>
            <template v-for="(value, key) in snackbarText2">
              <div v-if="!['errors', 'failedDetails', 'success_ids'].includes(key)" :key="key">{{ key | removeUnderscore }}: {{ value }}</div>
            </template>
          </template>
        </div>
        <template v-slot:action="">
          <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
        </template>
      </v-snackbar>

      <!-- users datatable -->
      <v-data-table
        :headers="headers"
        :items="invoices"
        item-key="id"
        sort-by="id"
        class="mx-0 my-0 px-5 v-sheet v-card fill-height"
        dense
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :show-expand="show_expand"
        :footer-props="{ 'items-per-page-options': [1, 2, 5, 10, 20], 'show-first-last-page': false, 'show-current-page': true, disablePagination: false }"
        :hide-default-footer="true"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn small color="primary" dark text outlined class="py-5 text-capitalize" @click="initialize()">
              <v-icon small>mdi-refresh</v-icon>
              <span>Refresh</span>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">More info about {{ item.id }}</td>
        </template>

        <template v-slot:[`item.invoice_pdf`]="{ item }">
          <a :href="item.invoice_pdf">Visit</a>
        </template>

        <template v-slot:[`item.hosted_invoice_url`]="{ item }">
          <a :href="item.hosted_invoice_url">Visit</a>
        </template>
        <template v-slot:[`item.total`]="{ item }">
          <span>{{ item.total / 100 }}</span>
        </template>

        <!-- Empty table slot -->
        <template v-slot:no-data>
          <div>No subscriptions found...</div>
        </template>

        <template v-slot:footer>
          <div class="custom-footer py-8 d-flex flex-row align-baseline justify-center flex-wrap">
            <v-btn small color="primary" text class="py-5 text-capitalize" @click="previous_page()" :disabled="options.page <= 1">
              <v-icon small class="">mdi-arrow-left-thin</v-icon>
              <span>Previous</span>
            </v-btn>
            <!-- Custom element content -->
            <v-btn small color="primary" text class="py-5 text-capitalize" @click="next_page()" :disabled="!has_more">
              <span>Next</span>
              <v-icon small>mdi-arrow-right-thin</v-icon>
            </v-btn>
            <!-- Custom element content -->
            <!-- <v-pagination v-if="hasMore" v-model="currentPage" :length="currentPage + 1" @input="fetchNextPage"></v-pagination> -->
            <!-- <v-pagination  v-if="has_more" v-model="options.page" @next="options.page++" @previous="options.page--" :length="options.page + 1"></v-pagination> -->
            <!-- <v-pagination v-model="options.page" :length="has_more ? options.page + 1 : options.page"></v-pagination> -->
          </div>
        </template>
      </v-data-table>

      <!-- Data table for contct list: end -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  // props: ["users", "usersRequest"],
  data: () => ({
    invoices: [],

    headers: [
      { text: "ID", align: "start", sortable: true, value: "id" },
      { text: "status", value: "status" },
      { text: "View", value: "hosted_invoice_url" },
      { text: "PDF Link", value: "invoice_pdf" },
      { text: "Amount", value: "total" },
      //   { text: "Credit Limit", value: "plan.options.credit" },
      //   { text: "Remaining Credit", value: "credit" },
      //   { text: "", value: "data-table-expand" },
    ],
    show_expand: false,
    expanded: [],
    singleExpand: true,
    options: {
      page: 1,
      itemsPerPage: 10,
      itemsLength: 0,
      sortBy: ["id"],
      sortDesc: [true],
    },
    invoice_count: 0,
    has_more: true,
    last_invoice_id: null,
    page_next: true,

    requestSent: false,

    // alerts fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",
  }),

  computed: {
    loading() {
      return this.requestSent;
    },
    starting_after() {
      return this.invoices.length ? this.invoices[this.invoices.length - 1].id : null;
    },
    ending_before() {
      return this.invoices.length ? this.invoices[0].id : null;
    },
  },

  watch: {
    async options() {
      await this.get_invoices();
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      this.invoices = [];
      this.options.page = 1;
      this.page_next = true;
      await this.get_invoices();
    },
    async get_invoices() {
      try {
        if (this.requestSent) return;

        this.requestSent = true;
        let res = await this.axios.post("/api/subscriptions/getusersinvoices", {
          options: this.options,
          starting_after: this.starting_after,
          ending_before: this.ending_before,
          next: this.page_next,
        });
        this.requestSent = false;
        console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);
        this.invoices = res.data.invoices;
        if (this.page_next) this.has_more = res.data.has_more;
        else this.has_more = true;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", error.message ? error.message : "Error: Unable to get user subscriptions");
      }
    },

    async go_to_client_dashboard() {
      try {
        if (this.requestSent) return;

        this.requestSent = true;
        let res = await this.axios.get("/api/subscriptions/getusersubmanagementlink");
        this.requestSent = false;
        console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);
        window.open(res.data.link, "_blank");
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", error.message ? error.message : "Error: Unable to get open customer dashboard for current user");
      }
    },

    async next_page() {
      this.page_next = true;
      this.options.page++;
      await this.get_invoices();
    },
    async previous_page() {
      this.page_next = false;
      this.options.page--;
      await this.get_invoices();
    },

    // snackbar and overlay
    setSnackbar(show, title, text) {
      this.showSnackbar = show;
      this.snackbarText = title;
      this.snackbarText2 = text;
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
}
td a {
  text-decoration: none;
}
::v-deep .v-list-item__title {
  text-transform: capitalize;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
