<template>
  <div ref="form" class="d-flex justify-center" style="flex-direction: column">
    <!-- <h4 class="text-center text-uppercase black--text py-5">Login</h4> -->
    <p class="text-center">Kindly provide your email to continue</p>
    <v-text-field @keyup.enter="validate()" v-model="email" label="Email" required solo dense></v-text-field>

    <v-btn style="background-color: #001536; color: #fcfcfc" class="my-0" @click="validate()" :disabled="requestSent">Submit</v-btn>
    <div class="small-font mt-2">{{ status }}</div>
  </div>
</template>

<script>
export default {
  data: () => ({
    email: "",
    requestSent: false,
    status: "",
  }),

  methods: {
    async validate() {
      try {
        this.status = "Verifying email...";
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) throw new Error("Kindly provide a valid email to continue");

        // sending request to api
        this.requestSent = true;
        let res = await this.axios.post("/api/users/sendaccessverificationemail", {
          email: this.email.toLowerCase(),
        });
        this.requestSent = false;
        this.status = res.data.message;
        if (!res.data.success) throw new Error(res.data.message);
        localStorage.setItem("email", res.data.email);
        this.$router.push({ name: "BlogWriting" });
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.status = error.message ? error.message : "Error - Could not verify email";
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
::v-deep .small-font,
::v-deep .v-label,
::v-deep .v-input {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
