<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({}),
  async mounted() {


    // login code
    let token = localStorage.getItem("token");
    if (token && this.$route.name != "BlogWriting") return this.$router.push({ name: "BlogWriting" });
    else if (!token && this.$route.name != "Login") return this.$router.push({ name: "Login" });
  },
};
</script>

<style></style>
