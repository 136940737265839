const goblin_quotes = [
  "Goblins are mythical creatures that are often depicted as mischievous or malevolent.",
  "They are usually depicted as small and ugly with sharp teeth, pointy ears, and green skin.",
  "Goblins are often associated with dark places like caves and underground tunnels.",
  "They are said to have a talent for metalworking, especially in crafting swords and other weapons.",
  "In folklore, goblins are often blamed for the disappearance of household items, such as keys and socks.",
  "Goblins are believed to have originated in European folklore and mythology.",
  "The word 'goblin' comes from the Old French word 'gobelin'.",
  "In some cultures, goblins are believed to be the souls of unbaptized children.",
  "Goblins are often associated with Halloween and other spooky holidays.",
  "In J.R.R. Tolkien's 'The Hobbit,' the goblin king is a major antagonist.",
  "Goblins are a common enemy in many fantasy video games and tabletop RPGs.",
  "In some folklore, goblins are said to be able to shape shift into animals or other creatures.",
  "Goblins are often portrayed as having a particular fondness for gold and other shiny objects.",
  "Some goblins are said to be able to create illusions or cast spells.",
  "In some cultures, goblins are believed to be able to possess humans.",
  "Goblins are sometimes associated with the devil or demons.",
  "In some folklore, goblins are believed to be able to control the weather.",
  "Goblins are sometimes depicted as living in trees or other natural places.",
  "Some goblins are said to have the ability to speak with animals.",
  "In some legends, goblins are believed to be able to grant wishes.",
  "Goblins are often depicted as being afraid of sunlight or fire.",
  "In some cultures, goblins are believed to be able to steal people's souls.",
  "Goblins are sometimes associated with trickery and deception.",
  "In some folklore, goblins are believed to be able to control the minds of humans.",
  "Goblins are sometimes depicted as being able to regenerate lost limbs or other body parts.",
  "Some goblins are said to have the power of flight.",
  "In some cultures, goblins are believed to be able to change their size or shape at will.",
  "Goblins are often portrayed as being obsessed with cleanliness or order.",
  "In some folklore, goblins are said to be able to curse people.",
  "Goblins are often portrayed as being cowardly and easily frightened.",
];

const tones = [
  { text: "Formal", desc: "serious, professional, respectful, polite, courteous" },
  { text: "Informal", desc: "casual, relaxed, friendly, approachable, lighthearted" },
  { text: "Sarcastic", desc: "ironic, tongue-in-cheek, wry, humorous, witty" },
  { text: "Sympathetic", desc: "understanding, compassionate, caring, empathetic, supportive" },
  { text: "Encouraging", desc: "positive, upbeat, optimistic, motivational, inspirational" },
  { text: "Empathetic", desc: "concerned, understanding, kind, caring, supportive" },
  { text: "Humorous", desc: "funny, amusing, entertaining, lighthearted, whimsical" },
  { text: "Passionate", desc: "enthusiastic, excited, energetic, dynamic, fervent" },
  { text: "Confident", desc: "assured, self-assured, bold, secure, optimistic" },
  { text: "Assertive", desc: "decisive, forceful, self-assured, confident, bold" },
  { text: "Persuasive", desc: "convincing, compelling, influential, persuasive, argumentative" },
  { text: "Diplomatic", desc: "tactful, respectful, understanding, courteous, polite" },
  { text: "Respectful", desc: "polite, courteous, considerate, dignified, professional" },
  { text: "Polite", desc: "courteous, considerate, respectful, well-mannered, gentlemanly/ladylike" },
  { text: "Supportive", desc: "encouraging, helpful, understanding, caring, kind" },
  { text: "Understanding", desc: "empathetic, compassionate, sympathetic, supportive, considerate" },
  { text: "Caring", desc: "compassionate, sympathetic, understanding, kind, considerate" },
  { text: "Kind", desc: "considerate, compassionate, understanding, caring, respectful" },
  { text: "Emotional", desc: "passionate, expressive, intense, heartfelt, dramatic" },
  { text: "Conversational", desc: "relaxed, natural, friendly, approachable, casual" },
  { text: "Businesslike", desc: "professional, efficient, practical, business-oriented, formal" },
  { text: "Energetic", desc: "enthusiastic, lively, dynamic, upbeat, motivated" },
  { text: "Cheerful", desc: "happy, upbeat, positive, joyful, optimistic" },
  { text: "Witty", desc: "humorous, amusing, entertaining, clever, sarcastic" },
  { text: "Inspiring", desc: "motivational, uplifting, encouraging, optimistic, positive" },
  { text: "Motivational", desc: "inspiring, uplifting, encouraging, positive, optimistic" },
  { text: "Respectful", desc: "polite, courteous, dignified, professional, well-mannered" },
  { text: "Thoughtful", desc: "considerate, respectful, compassionate, understanding, caring" },
  { text: "Considerate", desc: "respectful, understanding, caring, compassionate, polite" },
  { text: "Heartfelt", desc: "sincere, genuine, emotional, compassionate, caring" },
];

const email_intentions = [
  { text: "Grab Attention", value: "attention" },
  { text: "Promote a Product", value: "promotion" },
  { text: "Provide Information", value: "informative" },
  { text: "Make a Request", value: "request" },
  { text: "Follow-up on a Matter", value: "follow-up" },
  { text: "Send a Reminder", value: "reminder" },
  { text: "Request Feedback", value: "feedback" },
  { text: "Send an Invitation", value: "invitation" },
  { text: "Reply", value: "reply" },
];

const social_post_styles = [
  { text: "Personal Share", value: "personal-share" },
  { text: "Announcement", value: "announcement" },
  { text: "Tip", value: "tip" },
  { text: "Invitation", value: "invitation" },
  { text: "Social Proof", value: "social-proof" },
];

const social_post_intentions = [
  { text: "Book A Call", value: "book-a-call" },
  { text: "Share Information", value: "share-information" },
  { text: "Promote a Product", value: "promote-product" },
  { text: "Engage Audience", value: "engage-audience" },
  { text: "Provide Updates", value: "provide-updates" },
  { text: "Educate Audience", value: "educate-audience" },
  { text: "Entertain Audience", value: "entertain-audience" },
  { text: "Inspire Audience", value: "inspire-audience" },
  { text: "Ask for Feedback", value: "ask-for-feedback" },
  { text: "Share Testimonials", value: "share-testimonials" },
];

const tagline_lengths = [
  { text: "Ultra Short", value: "5 words" },
  { text: "Short", value: "8 words" },
  { text: "Medium", value: "11 words" },
];

const tagline_audiances = [
  { text: "Male", value: "male" },
  { text: "Female", value: "female" },
  { text: "Every gender", value: "every gender" },
  { text: "Straight", value: "straight" },
  { text: "Gay", value: "gay" },
  { text: "Irrelevant", value: "irrelevant" },
  { text: "Age 18 - 25", value: "ages between 18-25" },
  { text: "Age 25 - 35", value: "ages between 25-35" },
  { text: "Age 35 - 50", value: "ages between 35-50" },
  { text: "Age 50 - 70", value: "ages between 50-70" },
  { text: "Age 70+", value: "ages 70+" },
  { text: "Individual", value: "individual" },
  { text: "Business Owner", value: "business_owner" },
];

const tagline_industry_options = [
  { text: "Personal Development", value: "Personal Development" },
  { text: "Professional Development", value: "Professional Development" },
  { text: "Technology", value: "technology" },
  { text: "Finance", value: "finance" },
  { text: "Healthcare", value: "healthcare" },
  { text: "Retail", value: "retail" },
  { text: "Education", value: "education" },
  { text: "Automotive", value: "automotive" },
  { text: "Hospitality", value: "hospitality" },
  { text: "Media and Entertainment", value: "media_entertainment" },
  { text: "Real Estate", value: "real_estate" },
  { text: "Travel and Tourism", value: "travel_tourism" },
  { text: "Food and Beverage", value: "food_beverage" },
  { text: "Fashion and Apparel", value: "fashion_apparel" },
  { text: "E-commerce", value: "e_commerce" },
  { text: "Art and Design", value: "art_design" },
  { text: "Energy and Utilities", value: "energy_utilities" },
  { text: "Manufacturing", value: "manufacturing" },
  { text: "Transportation and Logistics", value: "transportation_logistics" },
  { text: "Sports and Fitness", value: "sports_fitness" },
  { text: "Construction", value: "construction" },
  { text: "Telecommunications", value: "telecommunications" },
  // Add more industries as needed
];

const tagline_styles = [
  { text: "Question", value: "question" },
  { text: "Shocking Claim", value: "shocking_claim" },
  { text: "Fun Fact", value: "fun_fact" },
  { text: "Sharing a Secret", value: "sharing_secret" },
  { text: "Statistic", value: "statistic" },
  { text: "Top Tips", value: "top_tips" },
];

const video_length_options = [
  { text: "REEL - 30 seconds", value: "60" }, // 60 words - allows for 1 talking point + CTA
  { text: "REEL - 60 seconds", value: "130" }, // 130 words - allows for 2 talking points + CTA
  { text: "REEL - 90 seconds", value: "190" }, // 190 words - allows for 2 talking points + 1 piece of proof + CTA
  { text: "3 minutes", value: "380" }, // 380 words - allows for 2 talking points + 1 piece of proof + story telling + CTA
  { text: "5 minutes", value: "650" }, // 650 words - allows for 2 talking points + 1 piece of proof + story telling + claim + CTA
];

module.exports = {
  tones,
  goblin_quotes,
  email_intentions,
  social_post_styles,
  social_post_intentions,
  tagline_lengths,
  tagline_audiances,
  tagline_industry_options,
  tagline_styles,
  video_length_options,
};
