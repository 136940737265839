import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#992486", // Modify the primary color
        secondary: "#00FF00", // Modify the secondary color
        accent: "#0000FF", // Modify the accent color
        error: "#FF0000", // Modify the error color
        // Add or modify other color values as needed

        bstopbar: "#992486",
        bssidebar: "#f8f4eb",
        bstopbarfont: "#992486",
        bssidebarfont: "#f8f4eb",

        bsprimary1: "#ffffff",
        bssecondary1: "#000000",

        bsprimary2: "#f8f4eb",
        bssecondary2: "#992486",

        bssuccess: "#4973bd", //blue
        bsdanger: "#ed5b72",
        bserror: "#ed5b72",

        orange1: "#f9a01b",
        orange2: "#f5873b",
        pink1: "#ed5b72",
        white1: "#f8f4eb",
        purple1: "#992486",
        blue1: "#4973bd",

        // extras
        bssuccessbtns: "#dafde8",
        bssdangerbtns: "#fdd9d9"
      },
    },
  },
});
