<template>
  <!-- Data table for contct list: start -->

  <v-row class="py-0">
    <v-col cols="12">
      <v-overlay :value="loading" z-index="1000">
        <v-snackbar v-model="loading" timeout="-1" centered>
          <span class="py-2"><v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular></span>
          <span class="py-2 px-2">Processing...</span>
        </v-snackbar>
      </v-overlay>
      <!-- Snackbar for small info alerts -->
      <v-snackbar
        :value="showSnackbar"
        timeout="-1"
        :vertical="true"
        app
        bottom
        right
        style="opacity: 0.9; z-index: 2005"
        content-class="main-snackbar-content d-flex flex-column align-start justify-start"
      >
        <div class="py-0" style="font-size: 15px">
          <b>{{ snackbarText }}</b>
        </div>
        <div class="py-1 text-capitalize" style="font-size: 13px">
          <template v-if="typeof snackbarText2 == 'string'">
            <span>{{ snackbarText2 }}</span>
          </template>
          <template v-else>
            <template v-for="(value, key) in snackbarText2">
              <div v-if="!['errors', 'failedDetails', 'success_ids'].includes(key)" :key="key">{{ key | removeUnderscore }}: {{ value }}</div>
            </template>
          </template>
        </div>
        <template v-slot:action="">
          <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
        </template>
      </v-snackbar>

      <!-- users datatable -->
      <v-data-table
        :headers="headers"
        :items="subscriptions"
        item-key="id"
        sort-by="id"
        class="mx-0 my-0 px-5 v-sheet v-card fill-height"
        dense
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :show-expand="show_expand"
        :options.sync="options"
        :server-items-length="subscriptions_count"
        :footer-props="{ 'items-per-page-options': [5, 10, 20] }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <!-- add / edit user modal -->

            <v-btn small color="primary" dark text outlined class="py-5 text-capitalize" @click="go_to_client_dashboard()">
              <v-icon small class="mr-1">mdi-account-multiple-plus-outline</v-icon>
              <span>Manage</span>
            </v-btn>
            <v-btn small color="primary" dark text outlined class="py-5 text-capitalize" @click="initialize()">
              <v-icon small>mdi-refresh</v-icon>
              <span>Refresh</span>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">More info about {{ item.id }}</td>
        </template>

        <!-- Empty table slot -->
        <template v-slot:no-data>
          <div>No subscriptions found...</div>
        </template>
      </v-data-table>
      <!-- Data table for contct list: end -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  // props: ["users", "usersRequest"],
  data: () => ({
    subscriptions: [],

    headers: [
      { text: "Plan", align: "start", sortable: true, value: "plan.product_name" },
      { text: "Type", value: "type" },
      { text: "Interval", value: "interval" },
      { text: "Status", value: "active" },
      { text: "Credit Limit", value: "plan.options.credit" },
      { text: "Remaining Credit", value: "credit" },
      //   { text: "", value: "data-table-expand" },
    ],
    show_expand: false,
    expanded: [],
    singleExpand: true,
    options: {
      page: 1,
      itemsPerPage: 20,
      itemsLength: 0,
      sortBy: ["id"],
      sortDesc: [true],
    },
    subscriptions_count: 0,

    requestSent: false,

    // alerts fields
    showSnackbar: false,
    snackbarText: "",
    snackbarText2: "",
  }),

  computed: {
    loading() {
      return this.requestSent;
    },
  },

  watch: {
    options() {
      this.get_subscriptions();
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      this.options.page = 1;
      await this.get_subscriptions();
    },
    async get_subscriptions() {
      try {
        if (this.requestSent) return;

        this.requestSent = true;
        let res = await this.axios.post("/api/subscriptions/getusersubscriptions", { options: this.options });
        this.requestSent = false;
        console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);
        this.subscriptions = res.data.subscriptions;
        this.subscriptions_count = res.data.count;
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", error.message ? error.message : "Error: Unable to get user subscriptions");
      }
    },

    async go_to_client_dashboard() {
      try {
        if (this.requestSent) return;

        this.requestSent = true;
        let res = await this.axios.get("/api/subscriptions/getusersubmanagementlink");
        this.requestSent = false;
        console.log(res.data);
        if (!res.data.success) throw new Error(res.data.message);
        window.open(res.data.link, "_blank");
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.setSnackbar(true, "Error", error.message ? error.message : "Error: Unable to get open customer dashboard for current user");
      }
    },

    // snackbar and overlay
    setSnackbar(show, title, text) {
      this.showSnackbar = show;
      this.snackbarText = title;
      this.snackbarText2 = text;
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
}
td a {
  text-decoration: none;
}
::v-deep .v-list-item__title {
  text-transform: capitalize;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}
</style>
