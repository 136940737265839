<template>
  <v-row justify="center">
    <!-- Snackbar for small info alerts -->
    <v-snackbar
      @input="update_snackbar"
      :value="showSnackbar"
      timeout="1000"
      :vertical="true"
      app
      bottom
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template>
          <div>{{ snackbarText2 }}</div>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- main dialog box -->
    <v-dialog :value="show" persistent fullscreen hide-overlay transition="dialog-bottom-transition" class="rounded-0">
      <v-card>
        <v-toolbar dense dark color="primary" class="rounded-0 read-mode-toolbar">
          <v-spacer></v-spacer>
          <div>
            <div>Read Mode</div>
          </div>
          <v-spacer></v-spacer>
          <div>
            <!-- copy button -->
            <v-tooltip color="bssecondary1" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2 pa-2" color="white" dense small v-bind="attrs" v-on="on" @click="copyResult">mdi-content-copy</v-icon>
              </template>
              <span>Copy Results</span>
            </v-tooltip>

            <v-btn small icon dark @click="$emit('close-read-dialog', false)">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>

        <div class="d-flex flex-row justify-center align-center" style="width: 100%">
          <pre class="py-8 px-2" style="max-width: 700px" v-html="text"></pre>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["show", "text"],
  data: () => {
    return {
      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",
    };
  },
  methods: {
    //   copy results to clipboard
    async copyResult() {
      try {
        if (!this.text) return;
        await navigator.clipboard.writeText(this.text);
        this.snackbarShow(true, "Text Copied", "");
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", "Text could not be copied to clipboard, kindly try doing it manually");
      }
    },
    // show/unshow snakbar => mostly errors
    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
    update_snackbar(v) {
      this.showSnackbar = v;
    },
  },
};
</script>

<style>
.read-mode-toolbar {
  height: 50px;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 12px;
  line-height: 1.75rem;
  width: 100%;
  font: inherit;
  font-weight: 400;
  font-size: 16px !important;
}
</style>
