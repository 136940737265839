<template>
  <!-- View Layout -->
  <v-row class="d-flex flex-row justify-space-between align-start ma-0 pa-0" align="stretch">
    <!-- View header -->
    <text-view-dialog :show="show_read_dialog" :text="result" @close-read-dialog="(v) => (show_read_dialog = v)"></text-view-dialog>

    <v-overlay :value="loading" style="z-index: 20001" :opacity="0.9">
      <v-snackbar v-model="loading" timeout="-1" centered>
        <div class="py-2 d-flex flex-column align-center justify-center">
          <v-progress-circular :size="25" color="primary" indeterminate></v-progress-circular>
          <!-- <span class="pa-2 text-center" style="line-height: 2em" v-html="loading_quote"></span> -->
        </div>
        <div class="py-2 d-flex align-baseline justify-center">Processing</div>
      </v-snackbar>
    </v-overlay>

    <!-- Snackbar for small info alerts -->
    <v-snackbar
      @input="update_snackbar"
      :value="showSnackbar"
      timeout="2000"
      :vertical="true"
      app
      bottom
      right
      style="opacity: 1; z-index: 2005"
      content-class="main-snackbar-content"
      elevation="24"
    >
      <div class="py-0" style="font-size: 13px">{{ snackbarText }}</div>
      <div class="py-1 text-capitalize" style="font-size: 13px">
        <template v-if="typeof snackbarText2 == 'string'">
          <div>{{ snackbarText2 }}</div>
        </template>
        <template v-else>
          <template v-for="(value, key) in snackbarText2">
            <div v-if="!['errors', 'failedDetails'].includes(key)" :key="key">{{ key }}: {{ value }}.</div>
          </template>
        </template>
      </div>
      <template v-slot:action="">
        <v-btn small color="blue" text @click="showSnackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <!-- Input Column -->
    <v-col cols="12" md="7" class="pa-0 d-flex">
      <v-sheet color="white" elevation="3" rounded class="mr-md-1 pb-3 mx-0 column-content w-100">
        <!-- filters start -->
        <v-row class="px-5 pt-5 pb-0 align-baseline justify-center" no-gutters>
          <v-col cols="12" md="12">
            <v-row>
              <!-- headings column + row -->
              <v-col cols="12" md="12" class="mr-0 py-0 pb-2">
                <h3 class="text-left">ARTICLE ALCHEMIST</h3>
                <div class="py-1 sub-heading">Simply enter your preferences so we can create all of your blogs, articles and news pieces.</div>
                <div class="py-5 sub-heading">
                  Compelling copy is the backbone of killer online content. Let’s get your voice visible online and ensure your brand receives the bandwidth it
                  deserves.
                </div>
              </v-col>

              <!-- property information column -->
              <v-col cols="12" md="7" class="mr-0 pr-0 py-0 d-flex flex-column align-baseline justify-start">
                <v-sheet color="bsprimary2" elevation="3" rounded class="pa-4 mb-2" width="100%" height="100%">
                  <div class="d-flex flex-row align-baseline justify-space-between w-100 ma-0 pa-0">
                    <h5 class="mb-4 text-left">Let’s nail those details:</h5>
                    <h5 class="mb-4 d-flex flex-row align-baseline justify-space-around">
                      <v-tooltip color="bssecondary2" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on" class="mr-1">mdi-information-variant</v-icon>
                        </template>
                        <span>Include or exclude the field value from the generated blog</span>
                      </v-tooltip>
                      Select
                    </h5>
                  </div>
                  <!-- address field -->

                  <!-- data field -->
                  <template v-for="key of Object.keys(fields)">
                    <template v-if="fields[key].show">
                      <!-- Case: input type is text -->
                      <div v-if="fields[key].input == 'text'" :key="key" class="d-flex flex-row align-baseline justify-space-around pb-1">
                        <v-text-field v-model="fields[key].value" :label="fields[key].label" class="lc-input-fields rounded-0" hide-details dense solo>
                          <v-tooltip color="bssecondary2" bottom slot="append" v-if="fields[key].show_info">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                            </template>
                            <span v-html="fields[key].info"></span>
                          </v-tooltip>
                        </v-text-field>
                        <v-checkbox
                          v-if="fields[key].show_include"
                          v-model="fields[key].include"
                          small
                          class="ma-0 pa-0"
                          slot="append"
                          hide-details
                          dense
                          solo
                        ></v-checkbox>
                      </div>

                      <!-- case: input type is combobox -->
                      <div v-if="fields[key].input == 'combobox'" :key="key" class="d-flex flex-row align-baseline justify-space-around pb-1">
                        <v-combobox
                          :label="fields[key].label"
                          v-model="fields[key].value"
                          clearable
                          dense
                          multiple
                          small-chips
                          solo
                          class="lc-input-fields"
                          deletable-chips
                          hide-details
                        >
                          <v-tooltip color="bssecondary2" bottom slot="append" v-if="fields[key].show_info">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                            </template>
                            <span>{{ fields[key].info }}</span>
                          </v-tooltip>
                        </v-combobox>
                        <v-checkbox
                          v-if="fields[key].show_include"
                          v-model="fields[key].include"
                          class="ma-0 pa-0"
                          slot="append"
                          hide-details
                          dense
                          solo
                        ></v-checkbox>
                      </div>

                      <!-- case: input type is switch button -->
                      <div v-if="fields[key].input == 'switch'" :key="key" class="ma-0 pa-0 d-flex flex-column align-baseline justify-start">
                        <v-switch
                          v-model="fields[key].value"
                          :label="fields[key].value ? fields[key].input_data.label_on : fields[key].input_data.label_off"
                          class="my-2 py-0 d-flex flex-row align-baseline"
                          color="primary"
                          hide-details
                          flat
                          dense
                        >
                          <v-tooltip color="bssecondary2" bottom slot="append" v-if="fields[key].show_info">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                            </template>
                            <span>{{ fields[key].info }}</span>
                          </v-tooltip>
                        </v-switch>
                      </div>

                      <!-- case: input type is range slider -->
                      <div v-if="fields[key].input == 'range_slider'" :key="key" class="ma-0 pa-0 d-flex flex-column align-baseline justify-start">
                        <v-range-slider
                          v-model="fields[key].value"
                          :label="fields[key].label"
                          :min="fields[key].input_data.min"
                          :max="fields[key].input_data.max"
                          :step="fields[key].input_data.step"
                          thumb-label
                          hide-details
                          class="d-flex flex-row align-baseline justify-start w-100"
                        >
                          <template v-slot:prepend v-if="fields[key].show_info">
                            <v-tooltip color="bssecondary2" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                              </template>
                              <span>{{ fields[key].info }}</span>
                            </v-tooltip>
                          </template>
                        </v-range-slider>
                      </div>

                      <!-- case: input type is slider -->
                      <div v-if="fields[key].input == 'slider'" :key="key" class="ma-0 pa-0 d-flex flex-column align-baseline justify-start">
                        <v-slider
                          v-model="fields[key].value"
                          :label="fields[key].label"
                          :min="fields[key].input_data.min"
                          :max="fields[key].input_data.max"
                          :step="fields[key].input_data.step"
                          thumb-label
                          hide-details
                          class="d-flex flex-row align-baseline justify-start w-100"
                        >
                          <template v-slot:prepend v-if="fields[key].show_info">
                            <v-tooltip color="bssecondary2" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                              </template>
                              <span>{{ fields[key].info }}</span>
                            </v-tooltip>
                          </template>
                          <template v-slot:append>
                            <span>{{ fields[key].input_data.append }}</span>
                          </template>
                        </v-slider>
                      </div>
                    </template>
                  </template>
                </v-sheet>
              </v-col>

              <!-- settings column -->
              <v-col cols="12" md="5" class="mr-0 pr-0 py-0 settings-div">
                <v-sheet color="bsprimary2" elevation="3" rounded class="pa-4 mb-2">
                  <div class="w-100 pt-0 d-flex flex-row align-baseline justify-space-between">
                    <h5 class="mb-4 text-left">Settings</h5>
                    <v-btn color="white" class="icon-only" @click="set_default_setting_fields()" small elevation="2">
                      <v-icon class="" small style="cursor: pointer">mdi-reload</v-icon>
                    </v-btn>
                  </div>

                  <!-- data field -->
                  <template v-for="key of Object.keys(setting_fields)">
                    <template v-if="setting_fields[key].show">
                      <!-- case: input type is combobox -->
                      <div v-if="setting_fields[key].input == 'combobox'" :key="key" class="d-flex flex-row align-baseline justify-space-around pb-1">
                        <v-combobox
                          :label="setting_fields[key].label"
                          v-model="setting_fields[key].value"
                          :items="setting_fields[key].input_data.items"
                          :item-value="setting_fields[key].input_data.item_value"
                          clearable
                          dense
                          multiple
                          small-chips
                          solo
                          class="lc-input-fields"
                          deletable-chips
                          hide-details
                          :return-object="false"
                        >
                          <v-tooltip color="bssecondary2" bottom slot="append" v-if="setting_fields[key].show_info">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                            </template>
                            <span>{{ setting_fields[key].info }}</span>
                          </v-tooltip>
                        </v-combobox>
                        <v-checkbox
                          v-if="setting_fields[key].show_include"
                          v-model="setting_fields[key].include"
                          class="ma-0 pa-0"
                          slot="append"
                          hide-details
                          dense
                          solo
                        ></v-checkbox>
                      </div>

                      <!-- case: input type is range slider -->
                      <div v-if="setting_fields[key].input == 'range_slider'" :key="key" class="ma-0 pa-0 d-flex flex-column align-baseline justify-start">
                        <v-range-slider
                          v-model="setting_fields[key].value"
                          :label="setting_fields[key].label"
                          :min="setting_fields[key].input_data.min"
                          :max="setting_fields[key].input_data.max"
                          :step="setting_fields[key].input_data.step"
                          thumb-label
                          hide-details
                          class="d-flex flex-row align-baseline justify-start w-100"
                        >
                          <template v-slot:prepend v-if="setting_fields[key].show_info">
                            <v-tooltip color="bssecondary2" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                              </template>
                              <span>{{ setting_fields[key].info }}</span>
                            </v-tooltip>
                          </template>
                        </v-range-slider>
                      </div>

                      <!-- case: input type is slider -->
                      <div v-if="setting_fields[key].input == 'slider'" :key="key" class="ma-0 pa-0 d-flex flex-column align-baseline justify-start">
                        <v-slider
                          v-model="setting_fields[key].value"
                          :label="setting_fields[key].label"
                          :min="setting_fields[key].input_data.min"
                          :max="setting_fields[key].input_data.max"
                          :step="setting_fields[key].input_data.step"
                          thumb-label
                          hide-details
                          class="d-flex flex-row align-baseline justify-start w-100"
                        >
                          <template v-slot:prepend v-if="setting_fields[key].show_info">
                            <v-tooltip color="bssecondary2" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                              </template>
                              <span>{{ setting_fields[key].info }}</span>
                            </v-tooltip>
                          </template>
                          <template v-slot:append>
                            <span>{{ setting_fields[key].input_data.append }}</span>
                          </template>
                        </v-slider>
                      </div>
                    </template>
                  </template>

                  <div class="w-100 pt-4 d-flex flex-row flex-wrap align-baseline justify-end">
                    <v-btn block class="my-1 ml-1 success-btns" @click="submit_request()" small elevation="2">
                      <v-icon class="mr-1" small>mdi-check</v-icon>
                      Generate
                    </v-btn>

                    <v-btn block class="my-1 success-btns" @click="submit_request()" small elevation="2">
                      <v-icon class="mr-1" small>mdi-repeat-variant</v-icon>
                      Regenerate
                      <v-tooltip color="bssecondary2" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="bssecondary2" dense small v-bind="attrs" v-on="on">mdi-information-variant</v-icon>
                        </template>
                        <span>Don't like this article? We will generate a different version of this for you if you click 'Regenerate'</span>
                      </v-tooltip>
                    </v-btn>

                    <v-btn block class="my-1 danger-btns" @click="setDefaults()" small elevation="2">
                      <v-icon class="mr-1" small>mdi-reload</v-icon>
                      <span>Start New</span>
                    </v-btn>
                  </div>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col cols="12">
            <v-sheet color="white" elevation="3" rounded class="ma-2 ml-5">
              <template>
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="history"
                  :options.sync="options"
                  :server-items-length="history_length"
                  item-key="id"
                  class="rounded-6 datatable-height"
                  :show-select="false"
                  :single-select="singleSelect"
                  :show-expand="false"
                  :single-expand="singleExpand"
                  :expanded.sync="expanded"
                  fixed-header
                  dense
                  :footer-props="{ 'items-per-page-options': [10, 20, 30, 50] }"
                  :loading="table_loading"
                  elevation="10"
                >
                  <!-- Actions slot -->
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip color="bssecondary2" left :open-delay="50">
                      <template v-slot:activator="{ on }">
                        <v-icon color="bsdanger" small class="mr-2" @click="remove_single_history(item.id)" v-on="on">mdi-trash-can</v-icon>
                      </template>
                      <span>Remove From History</span>
                    </v-tooltip>
                    <v-tooltip color="bssecondary2" left :open-delay="50">
                      <template v-slot:activator="{ on }">
                        <v-icon color="bssecondary2" small class="mr-2" @click="load_single_history(item)" v-on="on">mdi-arrow-up-circle</v-icon>
                      </template>
                      <span>Load History</span>
                    </v-tooltip>
                  </template>

                  <!-- Date slot -->
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ format_date(item.createdAt) }}
                  </template>

                  <template v-slot:top>
                    <!-- History row -->
                    <v-row class="px-4 py-1 align-baseline justify-center" no-gutters>
                      <v-col cols="12" md="12" class="mx-0 px-0mr-0 py-0">
                        <h4 class="my-2 mx-0 px-0 text-left pr-2 w-100 d-flex flex-row align-baseline justify-space-between">
                          <span>History</span>
                          <v-btn color="white" style="text-transform: capitalize" @click="loadHistory()" small elevation="2">
                            <v-icon class="mr-1" small>mdi-reload</v-icon>
                            Refresh
                          </v-btn>
                        </h4>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </template>
            </v-sheet>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <!-- Result column -->
    <v-col cols="12" sm="12" md="5" class="d-flex justify-center my-sm-2 my-md-0 pa-0">
      <v-sheet color="white" elevation="3" rounded class="px-5 mx-0 w-100">
        <div class="d-flex flex-row justify-space-between align-center">
          <h5 class="my-3 text-left">Request cost: {{ credit_per_request.marketing?.blog || 0 }}</h5>
          <h5 class="mx-1 my-3 text-left">Result</h5>
          <h5 class="my-3 text-left d-flex flex-row justify-space-between align-center">
            <v-icon class="mr-1" small @click="get_user_tokens">mdi-reload</v-icon>
            Credit: {{ user_tokens }}
          </h5>
        </div>
        <div ref="form">
          <v-textarea
            v-if="result_editable"
            id="content"
            v-model="result"
            rows="20"
            :counter="160"
            required
            hide-details
            dense
            solo
            class="my-3 rounded-3"
          ></v-textarea>
          <template v-else>
            <v-alert class="result_alert" border="left" colored-border color="deep-purple accent-4" elevation="1">
              Double-click or select any word to be avoided in next revision of copy.
            </v-alert>
            <pre class="result_div" v-html="cresult" @mouseup="highlightSelected"></pre>
          </template>
        </div>

        <div class="d-flex justify-space-between align-center my-1">
          <div class="d-flex justify-center my-1 pa-1">
            <!-- copy button -->
            <v-tooltip color="bssecondary2" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2 pa-2" color="bssecondary2" dense small v-bind="attrs" v-on="on" @click="copyResult">mdi-content-copy</v-icon>
              </template>
              <span>Copy Results</span>
            </v-tooltip>

            <!-- clear highlight button -->
            <v-tooltip color="bssecondary2" top v-if="!result_editable">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2 pa-2" color="bssecondary2" dense small v-bind="attrs" v-on="on" @click="excluded = []">mdi-cancel</v-icon>
              </template>
              <span>Clear Highlighted</span>
            </v-tooltip>

            <!-- change mode button -->
            <v-tooltip color="bssecondary2" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2 pa-2" color="bssecondary2" dense small v-bind="attrs" v-on="on" @click="result_editable = !result_editable">
                  mdi-square-edit-outline
                </v-icon>
              </template>
              <span v-if="result_editable">Switch to exclude mode</span>
              <span v-else>Switch to editable mode</span>
            </v-tooltip>

            <!-- export mode button -->
            <v-tooltip color="bssecondary2" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2 pa-2" color="bssecondary2" dense small v-bind="attrs" v-on="on" @click="downloadDoc">mdi-download</v-icon>
              </template>
              <span>Export as doc</span>
            </v-tooltip>

            <!-- read mode button -->
            <v-tooltip color="bssecondary2" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2 pa-2" color="bssecondary2" dense small v-bind="attrs" v-on="on" @click="show_read_dialog = true">mdi-teamviewer</v-icon>
              </template>
              <span>Open read or distraction free mode</span>
            </v-tooltip>
          </div>
          <div>
            <v-btn v-for="(l, i) of stock_photo_links" :key="l + i" color="white" class="py-1 ml-0 mr-1 mb-1 text-capitalize" @click="visit_link(l.l)" small>
              <v-icon class="mr-1" small>mdi-link</v-icon>
              {{ l.n }}
            </v-btn>
          </div>
          <h5 class="mx-1 my-3 text-left">
            Excluded Words: {{ excluded.length }}
            <br />
            Word Count: {{ word_count }}
          </h5>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import TextViewDialog from "../../../components/TextViewDialog.vue";
let data = require("./data");
let fns = require("./functions");

export default {
  name: "BlogWriting",
  components: {
    TextViewDialog,
  },
  data: () => {
    return {
      fields: {
        theme: {
          value: "",
          label: "Title or Theme of title",
          info: `Using Social Suite's tagline generator (HOOK HOUDINI), paste the title of your article here. <br/>Or simply tell us what this article is going to be about. <br/> Checking the checkbox will keep your entered title the same, uncheck or keep empty if you want us to generate a title for you`,
          show_info: true,
          include: true,
          show: true,
          show_include: true,
          type: "text",
          input: "text",
          input_data: {
            default: "",
          },
        },
        keypoints: {
          value: [],
          label: "Key points you want in the article",
          info: `Additional features: you can add multiple features in the field, press enter after every individual keypoint`,
          show_info: true,
          include: true,
          show: true,
          show_include: true,
          type: "text",
          input: "combobox",
          input_data: {
            default: [],
          },
        },
        keywords: {
          value: [],
          label: "Key words that you want included for SEO",
          info: "Include enough keywords for SEO, press enter after every keyword/tag",
          show_info: true,
          include: true,
          show: true,
          show_include: true,
          type: "text",
          input: "combobox",
          input_data: { default: [] },
        },

        seo_keywords: {
          value: true,
          label: "",
          info: "Include keywords for SEO or not",
          show_info: true,
          include: true,
          show: true,
          show_include: true,
          type: "text",
          input: "switch",
          input_data: {
            label_on: "Include enough keywords for SEO",
            label_off: "Don't Include enough keywords for SEO",
            default: true,
          },
        },
        excerpt: {
          value: true,
          label: "",
          info: "An excerpt can be used as a SEO Metatitle, article preview or you can use it to pitch an article.",
          show_info: true,
          include: true,
          show: true,
          show_include: true,
          type: "text",
          input: "switch",
          input_data: {
            label_on: "Generate Excerpt",
            label_off: "Do not generate Excerpt",
            default: true,
          },
        },
      },

      setting_fields: {
        words: {
          value: [200, 400],
          label: "Words",
          info: "Estimated length in words",
          show_info: true,
          include: true,
          show: true,
          show_include: true,
          type: "text",
          input: "range_slider",
          input_data: {
            min: 100,
            max: 800,
            step: 50,
            default: [200, 400],
          },
        },
        variety: {
          value: 5,
          label: "Variety",
          info: "How regularly Gerald pulls out his thesaurus to find different words to use in a sentence",
          show_info: true,
          include: true,
          show: true,
          show_include: true,
          type: "text",
          input: "slider",
          input_data: {
            min: 0,
            max: 10,
            step: 0.5,
            append: "/10",
            default: 5,
          },
        },
        creativity: {
          value: 5,
          label: "Creativity",
          info: "How creatively Gerald puts words together in a sentence",
          show_info: true,
          include: true,
          show: true,
          show_include: true,
          type: "text",
          input: "slider",
          input_data: {
            min: 0,
            max: 10,
            step: 0.5,
            append: "/10",
            default: 5,
          },
        },
        tones: {
          value: [],
          label: "Select tone",
          info: "Select upto 4 tones from the dropdown menu to be used for article generation. This will set the tone/mood for the article",
          show_info: true,
          include: true,
          show: true,
          show_include: false,
          type: "text",
          input: "combobox",
          input_data: {
            items: data.tones,
            item_text: "tone",
            item_value: "tone",
            default: [],
          },
        },
      },

      // Copy Writing Fields
      settings: {
        length: [200, 400],
        complexity: 8,
        enthusiasm: 8,
        tone: [],
        language: "AU English",
        model: "gpt-3.5",
      },
      length_options: ["short", "long"],
      blog: {
        theme: null,
        features: [],
        keywords: [],
        title: true,
        excerpt: true,
        social_post: true,
        photo_links: true,
      },
      include_field: {
        theme: true,
        features: true,
        title: true,
        excerpt: true,
        social_post: true,
        photo_links: true,
      },
      result: "",
      busy: false,

      // new fields
      headers: [
        { text: "Title", value: "name", align: "start", sortable: true },
        { text: "Date & Time", value: "createdAt", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
      history: [],
      history_length: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        itemsLength: 0,
        sortBy: ["id"],
        sortDesc: [true],
      },
      requestSent: false,

      loading_quote_default: `Gerald just back from his unannounced coffee break. <br /> Please wait while he generates your blog right now <br /> In the meantime, there are some interesting facts about goblins: <br /> `,
      loading_quote: "",

      // snackbar fields
      showSnackbar: false,
      snackbarText: "",
      snackbarText2: "",

      // table expansion
      expanded: [],
      singleExpand: false,

      // Table selection
      singleSelect: false,
      selected: [],
      table_loading: false,

      excluded: [],
      cresult: "",
      result_editable: true,

      user_tokens: null,

      stock_photo_sources: [
        { l: "https://www.shutterstock.com/search/", n: "Shutterstock" },
        { l: "https://unsplash.com/s/photos/", n: "Unsplash" },
      ],
      stock_photo_links: [],
      goblin_quotes: data.goblin_quotes,

      show_read_dialog: false,
      credit_per_request: {},
    };
  },
  computed: {
    loading() {
      return this.requestSent;
    },
    word_count() {
      return this.result ? this.result.split(" ").length : 0;
    },
  },
  watch: {
    result(newval) {
      this.cresult = newval;
    },
    excluded(newval) {
      let htext = this.result;
      for (let kw of newval) htext = htext.replace(new RegExp("\\b" + kw + "\\b", "gi"), `<span class='highlighted'>${kw}</span>`);
      this.cresult = htext;
    },
    // new fields
    options() {
      this.loadHistory();
    },
    requestSent(newval) {
      if (newval) this.loading_quote = this.loading_quote_default;
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    async initialize() {
      try {
        await this.get_user_tokens();
        await this.loadHistory();
        this.change_loading_quote();
      } catch (error) {}
    },
    async submit_request() {
      try {
        if (this.requestSent) return;
        this.requestSent = true;
        await new Promise((r) => setTimeout(r, 300)); // adding a delay for combobox to set its value on loosing focus

        const data = this.get_filtered_fields(this.fields);
        const settings = this.get_filtered_fields(this.setting_fields);
        const exclude = Object.assign([], this.excluded);
        this.stock_photo_links = []; //check its workflow later

        // handling theme field separately
        if (this.fields.theme.value) {
          if (this.fields.theme.include) {
            data.preserve_theme = { include: true, input: "text", value: "Keep the provided theme or title same in the blog" };
          } else {
            data.theme = this.fields.theme;
          }
        }

        // return console.log({ data, settings, exclude });

        const res = await this.axios.post(`/api/content/generateblog`, { data, settings, exclude });
        this.requestSent = false;

        if (!res.data.success) throw new Error(res.data.message);
        this.result = res.data.result.trim();
        this.history_length++;
        this.history = [res.data.history, ...this.history];

        // setting links
        if (this.fields["theme"] && this.fields["theme"].value)
          for (let s of this.stock_photo_sources) this.stock_photo_links.push({ l: `${s.l}${this.fields["theme"].value.trim().replace(/\s/g, "-")}`, n: s.n });

        await this.get_user_tokens();
      } catch (error) {
        this.requestSent = false;
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Response could not be generated");
      }
    },

    // setting defaults in data fields
    set_default_data_fields() {
      this.set_default_fields(this.fields);
    },
    // setting defaults for setting fields
    set_default_setting_fields() {
      this.set_default_fields(this.setting_fields);
    },

    get_filtered_fields(fields) {
      const data = {};
      try {
        for (const [k, f] of Object.entries(fields)) {
          if (!f.show) continue;

          if (Array.isArray(f.value) && !f.value.length) continue;
          else if (typeof f.value == "object" && !Object.keys(f.value).length) continue;
          else if (typeof f.value == "boolean" && [null, undefined].includes(f.value)) continue;
          else if (typeof f.value == "integer" && f.value < 0) continue;
          else if (typeof f.value == "string" && !f.value) continue;
          else {
            data[k] = { value: f.value, input: f.input, include: f.include };
          }
        }
        return data;
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Could not filter fields, try refreshing the page and starting again");
        return data;
      }
    },

    // main default setting funciton
    set_default_fields(fields) {
      try {
        for (const [k, f] of Object.entries(fields)) {
          if (Array.isArray(f.input_data.default)) {
            f.value = Object.assign([], f.input_data.default);
          } else if (typeof f.input_data.default == "object") f.value = Object.assign({}, f.input_data.default);
          else f.value = f.input_data.default;

          if (![null, undefined].includes(f.includes)) f.include = true;
        }
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "Defaults could not be set, there might be an issue with data types");
      }
    },

    setDefaults() {
      this.set_default_data_fields();
      this.set_default_setting_fields();
      this.result = "";
      this.excluded = [];
      this.stock_photo_links = [];
    },

    async copyResult() {
      try {
        if (!this.result) return;
        await navigator.clipboard.writeText(this.result);
        this.snackbarShow(true, "Text Copied", "");
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", "Text could not be copied to clipboard, kindly try doing it manually");
      }
    },
    async loadHistory() {
      try {
        // checking for other requests
        if (this.table_loading) return;
        this.table_loading = true;
        await new Promise((r) => setTimeout(r, 200));
        // sending request for history
        let res = await this.axios.post("/api/content/gethistory", { options: this.options, page: "blog" });
        this.table_loading = false;

        if (!res.data.success) throw new Error(res.data.message);

        this.history = res.data.history;
        this.history_length = res.data.count;
      } catch (error) {
        console.log(error);
        this.table_loading = false;
        this.snackbarShow(true, "Error", "History could not be loaded: check your internet connection");
      }
    },

    snackbarShow(show, heading, text) {
      this.showSnackbar = show;
      this.snackbarText = heading;
      this.snackbarText2 = text;
    },
    update_snackbar(a) {
      this.showSnackbar = a;
    },

    async load_single_history(h) {
      try {
        // setting default values for all fields
        this.setDefaults();
        this.table_loading = true;

        // handling data fields from request
        const data = h.request.data || {};
        for (const [k, f] of Object.entries(this.fields)) {
          if (data[k]) {
            f.value = data[k].value;
            f.include = data[k].include;
          }
        }

        // handling setting fields from request
        const settings = h.request.settings || {};
        for (const [k, f] of Object.entries(this.setting_fields)) {
          if (settings[k]) {
            f.value = settings[k].value;
            f.include = settings[k].include;
          }
        }

        // handling excluded keywords fields from request
        this.excluded = h.request.exclude;

        // handling result from response
        const result = h.response.result || "";
        this.result = result.trim();

        // handling photo links for buttons
        const theme = h.request.data.theme && h.request.data.theme.value ? h.request.data.theme.value : null;
        if (theme) for (let s of this.stock_photo_sources) this.stock_photo_links.push({ l: `${s.l}${theme.trim().replace(/\s/g, "-")}`, n: s.n });

        this.table_loading = false;
      } catch (error) {
        console.log(error);
        this.table_loading = false;
      }
    },
    async remove_single_history(id) {
      try {
        this.table_loading = true;
        let res = await this.axios.post("/api/users/removehistory", { id });
        console.log(res.data);
        this.table_loading = false;

        if (!res.data.success) throw new Error(res.data.message);
        const index = this.history.findIndex((h) => h.id == id);
        this.history.splice(index, 1);
        this.history_length--;

        // await this.loadHistory();
      } catch (error) {
        console.log(error);
        this.table_loading = false;
      }
    },
    async get_user_tokens() {
      try {
        const res = await this.axios.post("/api/subscriptions/getusercredit", { type: "marketing" });
        console.log("credit response", res);
        if (!res.data.success) throw new Error(res.data.message);
        this.user_tokens = res.data.credit;
        this.credit_per_request = res.data.credit_per_request;
      } catch (error) {
        console.log(error);
        this.snackbarShow(true, "Error", error.message ? error.message : "User credit could not be fetched");
      }
    },
    format_date(date) {
      if (!date) return date;
      return date.replace("T", " ").replace(/\.[^.]*$/gi, "");
    },
    downloadDoc() {
      let file_name = this.blog.theme ? this.blog.theme : "Blog";
      fns.download_doc(this.result, file_name);
    },
    highlightSelected() {
      let text = window.getSelection().toString().toLowerCase().trim();
      let keywords = text.split(" ");
      for (let kw of keywords) if (kw && kw.trim().length > 2 && !this.excluded.includes(kw)) this.excluded.push(kw.trim());
    },
    visit_link(l) {
      window.open(l);
    },
    change_loading_quote() {
      setInterval(() => {
        if (this.requestSent) {
          let max = this.goblin_quotes.length - 1;
          let min = 0;
          let rand = Math.floor(min + Math.random() * (max - min + 1));
          this.loading_quote = this.goblin_quotes[rand];
        }
      }, 7000);
    },
  },
};
</script>

<style scoped>
* {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu" !important;
}
::v-deep .small-font {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-label,
.v-input {
  font-weight: 500;
  font-size: 13px !important;
}
::v-deep .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}
.v-data-table {
  border: 1px solid #4b414124;
}
::v-deep th.text-start {
  min-width: 100px;
}
::v-deep th {
  font-weight: 800;
}
::v-deep td {
  font-weight: 500;
  font-size: 13px !important;
}

::v-deep .lc-input-fields {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

.settings-div .v-btn:not(.icon-only) {
  text-transform: initial;
  width: 146px;
  float: right;
}
.settings-div .v-btn .v-icon {
  width: 20px;
}
/* ::v-deep .v-input--selection-controls__ripple {

    margin: 0px !important;
} */
::v-deep .v-input__slider label {
  width: 75px;
}
.datatable-height {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.w-45 {
  width: 45% !important;
}
.h-100 {
  height: 100%;
}
.h-80 {
  height: 80%;
}
.highlighted {
  background-color: yellow;
}
.result_div {
  line-height: 1.5em;
}
::v-deep .result_div span {
  line-height: 1.5em;
  background-color: yellow !important;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  padding: 12px;
  line-height: 1.75rem;
  width: 100%;
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.result_alert {
  font: inherit;
  font-weight: 500;
  font-size: 13px !important;
}
.sub-heading {
  font-size: 14px;
  font-weight: 500;
}

::v-deep .v-list-item__title {
  text-transform: capitalize;
}

::v-deep .v-select.v-input--dense .v-chip {
  margin: 1px 4px;
}

/* scroll styling */
#content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(77, 75, 75, 0.3);
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar-thumb {
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}

/* backgrounds */
::v-deep .v-data-table {
  background-color: var(--v-bsprimary2-base) !important;
}
::v-deep .v-data-table th {
  background-color: var(--v-bsprimary1-base) !important;
}

.column-content {
  flex: 1;
  align-self: stretch;
}

.v-tooltip__content {
  max-width: 500px;
  white-space: normal;
  word-wrap: break-word;
}

::v-deep .success-btns {
  background-color: var(--v-bssuccessbtns-base) !important;
}
::v-deep .danger-btns {
  background-color: var(--v-bssdangerbtns-base) !important;
}
</style>
