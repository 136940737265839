import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";


import DashboardMain from "../views/dashboard/Main.vue";

// Auth Views & Components
import Account from "../views/auth/Main.vue";
import Login from "../views/auth/Login.vue";
// import Register from "../views/auth/Register.vue";
import ForgotPassword from "../views/auth/Forgot.vue";
import Logout from "../views/auth/Logout.vue";
import RegisterConfirmEmail from "../views/auth/RegisterConfirmEmail.vue";
import ForgotEmail from "../views/auth/ForgotEmail.vue";
import PendingVerification from "../views/auth/PendingVerification.vue";
import PendingAdminVerification from "../views/auth/PendingAdminVerification.vue";

// Property Listings Views & Components
import Users from "../views/dashboard/users/Main.vue";
import Notifications from "../views/dashboard/users/Notifications.vue";

// Users Views & Components
import ChangeSettings from "../views/dashboard/users/ChangeSettings.vue";
import ChangePassword from "../views/dashboard/users/ChangePassword.vue";
import ChangeUserSettings from "../views/dashboard/users/ChangeUserSettings.vue";
import ViewActivity from "../views/dashboard/users/ViewActivity.vue";

// listing scribe pages
import AccessByEmail from "../views/auth/AccessByEmail";
import AccessByEmailVerify from "../views/auth/AccessByEmailVerify.vue";

// blog writing components
import BlogWriting from "../views/dashboard/marketing/BlogWriting";
import EmailGenerator from "../views/dashboard/marketing/EmailGenerator";
import Social from "../views/dashboard/marketing/Social";
import Taglines from "../views/dashboard/marketing/Taglines";
import Video from "../views/dashboard/marketing/Video";

// subscription management routes
import UserSubscriptions from "../views/dashboard/subscriptions/UserSubscriptions";
import UserInvoices from "../views/dashboard/subscriptions/UserInvoices";
import Home from "../views/dashboard/home/Home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/account",
    name: "Account",
    component: Account,
    children: [
      { path: "login", name: "Login", component: Login },
      // { path: "register", name: "Register", component: Register },
      { path: "forgotpassword", name: "Forgot Password", component: ForgotPassword },
      { path: "logout", name: "Logout", component: Logout },
      // { path: "registerconfirm", name: "RegisterConfirmEmail", component: RegisterConfirmEmail },
      { path: "forgotemail", name: "ForgotEmail", component: ForgotEmail },
      // { path: "pendingverification", name: "PendingVerification", component: PendingVerification },
      // { path: "PendingAdminVerification", name: "PendingAdminVerification", component: PendingAdminVerification },

      { path: "accessbyemail", name: "AccessByEmail", component: AccessByEmail },
      { path: "accessbyemailverify", name: "AccessByEmailVerify", component: AccessByEmailVerify },
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardMain,
    children: [
      {
        path: "home",
        name: "Home",
        component: Home,
        meta: { page: "Home" },
      },
      {
        path: "articlealchimest",
        name: "BlogWriting",
        component: BlogWriting,
        meta: { page: "ARTICLE ALCHEMIST" },
      },
      {
        path: "emailmagician",
        name: "EmailGenerator",
        component: EmailGenerator,
        meta: { page: "Email Magician" },
      },
      {
        path: "socialsorcerer",
        name: "Social",
        component: Social,
        meta: { page: "Social Sorcerer" },
      },
      {
        path: "hookhoudini",
        name: "Taglines",
        component: Taglines,
        meta: { page: "Hook Houdini" },
      },
      {
        path: "videovodoo",
        name: "Video",
        component: Video,
        meta: { page: "Video Voodoo" },
      },

      {
        path: "users",
        name: "Users",
        component: Users,
        children: [
          { path: "manageaccount/changesettings", name: "ChangeSettings", component: ChangeSettings, meta: { page: "Edit Account" } },
          { path: "manageaccount/changepassword", name: "ChangePassword", component: ChangePassword, meta: { page: "Reset Password" } },
          { path: "manageusers/changeusersettings", name: "ChangeUserSettings", component: ChangeUserSettings, meta: { page: "Add Or Remove Users" } },
          { path: "manageusers/viewactivity", name: "ViewActivity", component: ViewActivity, meta: { page: "User Activity Logs" } },
          { path: "notifications", name: "Notifications", component: Notifications, meta: { page: "Notifications" } },
          { path: "subscriptions", name: "UserSubscriptions", component: UserSubscriptions, meta: { page: "Subscriptions" } },
          { path: "invoices", name: "UserInvoices", component: UserInvoices, meta: { page: "Invoices" } },
        ],
      },
    ],
  },
  // independednt pages
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("setHeading", to.meta.page);
  next();
});

export default router;
